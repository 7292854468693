<script setup lang="ts">
import { useDialogRef } from '@/utils/primevue';

const dialogRef = useDialogRef();
</script>

<template>
  <div mb-8>dialog-content.vue</div>
  <div class="flex justify-end gap-2">
    <Button type="button" label="关闭" severity="secondary" @click="dialogRef?.close"></Button>
    <Button type="button" label="关闭" @click="dialogRef?.close"></Button>
  </div>
</template>
