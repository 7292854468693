/**
 * 需要把 <DynamicDialog /> <ConfirmDialog /> <Toast /> 放在 App.vue 的 template 中
 */
import '../assets/reset-primevue.css';

// ========================================================================
// ========================== ConfirmationService =========================
// ========================================================================
// @ts-expect-error - Ignore missing types
import ConfirmationEventBus from 'primevue/confirmationeventbus';
import type { ConfirmationOptions } from 'primevue/confirmationoptions';
interface HConfirmationOptions extends ConfirmationOptions {
  rejectProps?: import('primevue/button').ButtonProps;
  acceptProps?: import('primevue/button').ButtonProps;
}
export const ConfirmationService = {
  require: (options: HConfirmationOptions) => {
    ConfirmationEventBus.emit('confirm', options);
  },
  close: () => {
    ConfirmationEventBus.emit('close');
  },
};

// ========================================================================
// ============================= ToastService =============================
// ========================================================================
// @ts-expect-error - Ignore missing types
import ToastEventBus from 'primevue/toasteventbus';
import type { ToastServiceMethods } from 'primevue/toastservice';
// https://github.com/primefaces/primevue/blob/61929eae7526015af0362fc5889f2af7527403d1/packages/primevue/src/toastservice/ToastService.js
export const ToastService: ToastServiceMethods = {
  add: (message) => {
    ToastEventBus.emit('add', message);
  },
  remove: (message) => {
    ToastEventBus.emit('remove', message);
  },
  removeGroup: (group) => {
    ToastEventBus.emit('remove-group', group);
  },
  removeAllGroups: () => {
    ToastEventBus.emit('remove-all-groups');
  },
};

// ========================================================================
// ============================= DialogService ============================
// ========================================================================
// @ts-expect-error - Ignore missing types
import DynamicDialogEventBus from 'primevue/dynamicdialogeventbus';
import type { DialogServiceMethods } from 'primevue/dialogservice';
// https://github.com/primefaces/primevue/blob/18367429f624285ff32d0ef775c1825a43a02fb1/packages/primevue/src/dialogservice/DialogService.js#L7
export const DialogService: DialogServiceMethods = {
  open: (content, options) => {
    const instance = {
      content: content && markRaw(content),
      options: options || {},
      data: options && options.data,
      close: (params: unknown) => {
        DynamicDialogEventBus.emit('close', { instance, params });
      },
    };

    DynamicDialogEventBus.emit('open', { instance });

    return instance;
  },
};

export function useDialogRef() {
  type DialogRef = ComputedRef<import('primevue/dynamicdialogoptions').DynamicDialogInstance>;
  return inject<DialogRef>('dialogRef');
}
